import activity from './m-field-trip-checkin.vue';

export default activity;

const componentRegistrationData = {
  name: 'Field Trip Check In/Out',
  icon: 'mdi-bus',
  description: 'Pilotcity Field Trip',
  styling: { borderColor: '#C3177E' },
  status: true,
  setupRequired: false,
  fields: [
    {
      roles: ['participant'],
      name: 'm-field-trip-checkin',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {},
      value: {
        dateCheckedIn: '',
        dateCheckedOut: '',
        isCheckedIn: false
      }
    }
  ]
};
