
import { defineComponent, ref, computed, Ref } from '@vue/composition-api';
import { Field } from '../index';

interface ParticipantData {
  /** The strings are in UTC time string format! * */
  dateCheckedIn: string;
  dateCheckedOut: string;
  isCheckedIn: boolean;
}

interface CheckinField {
  setup: never;
  value: ParticipantData;
}

export { ParticipantData, CheckinField };

export default defineComponent({
  name: 'MFieldTripCheckin',
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Field Trip Check-In/Out';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-field-trip-checkin';
          }
          return false;
        }
      );
      return Fdindx;
    });

    const fieldValue = computed<CheckinField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const participantData = computed<ParticipantData>({
      get: () => {
        if (!fieldValue.value.value) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          fieldValue.value.value = {
            dateCheckedIn: '',
            dateCheckedOut: '',
            isCheckedIn: false
          };
        }
        return fieldValue.value.value;
      },
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });

    const convertMSToTimeString = (ms: number) => {
      let msToConvert = ms;

      let hour = 0;
      let minute = 0;
      let seconds = 0;

      while (msToConvert - 3.6 * 10 ** 6 >= 0) {
        hour += 1;
        msToConvert -= 3.6 * 10 ** 6;
      }
      while (msToConvert - 6 * 10 ** 4 >= 0) {
        minute += 1;
        msToConvert -= 6 * 10 ** 4;
      }
      while (msToConvert - 1000 >= 0) {
        seconds += 1;
        msToConvert -= 1000;
      }

      return `${hour}:${minute.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    let timeIntervalId;
    const timeStringSinceCheckedIn = ref('');

    const checkIn = () => {
      participantData.value.isCheckedIn = true;
      participantData.value.dateCheckedIn = new Date().toUTCString();
    };

    const checkOut = () => {
      participantData.value.isCheckedIn = false;
      participantData.value.dateCheckedOut = new Date().toUTCString();
    };

    const toggleCheckIn = () => {
      if (participantData.value.isCheckedIn) {
        checkOut();
      } else {
        checkIn();
      }
    };

    return {
      fieldValue,
      toggleCheckIn,
      checkIn,
      checkOut,
      convertMSToTimeString,
      participantData,
      timeIntervalId,
      timeStringSinceCheckedIn
    };
  },
  mounted() {
    this.timeIntervalId = setInterval(() => {
      if (this.participantData.isCheckedIn) {
        this.timeStringSinceCheckedIn = this.convertMSToTimeString(
          new Date().getTime() - Date.parse(this.participantData.dateCheckedIn)
        );
      } else if (this.participantData.dateCheckedOut !== '') {
        const curTime = new Date().getTime();
        this.timeStringSinceCheckedIn = this.convertMSToTimeString(
          (this.timeStringSinceCheckedIn =
            Date.parse(this.participantData.dateCheckedOut) -
            Date.parse(this.participantData.dateCheckedIn))
        );
      } else {
        this.timeStringSinceCheckedIn = '';
      }
    }, 1000);
  },
  destroyed() {
    clearInterval(this.timeIntervalId);
  }
});
